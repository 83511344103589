import Tabby from "tabbyjs";

const defaultOptions = {
  idPrefix: "tabby-toggle_",
  default: "[data-tabby-default]",
};

export const init = (selector = "[data-tabs]", options = defaultOptions) => {
  const tabsSelectors = document.querySelectorAll(selector);
  tabsSelectors.forEach((tabsContainer, i) => {
    tabsContainer.setAttribute(`data-tabs-${i}`, "");

    if (tabsContainer.dataset.dataTabsPrefix) {
      options.idPrefix = tabsContainer.dataset.dataTabsPrefix;
    }

    // Provide support for multiple tab instances on the same page.
    const instanceSelector = `[data-tabs-${i}]`;

    // eslint-disable-next-line no-new
    new Tabby(instanceSelector, options);

    // Backward compatibility with semantic styles. Apply active in addition
    // to the built-in aria-selected behavior
    // Also allow hiding the content until tabs are done initializing.
    const tabWrapper = document.querySelector(selector);
    if (!tabWrapper) return;
    const tabs = tabWrapper.querySelectorAll("a");
    tabs.forEach((tab) => {
      if (
        (window.location.hash.length === 0 ||
          tab.getAttribute("href") === window.location.hash) &&
        tab.matches(options.default)
      ) {
        const content = document.querySelector(tab.hash);
        if (tab.dataset.activeClasses) {
          tab.classList.add(...tab.dataset.activeClasses.split(" "));
          tab.classList.remove(...tab.dataset.inactiveClasses.split(" "));
        } else {
          tab.classList.add("active");
        }
        content.removeAttribute("hidden");
      }
    });
  });
};

document.addEventListener("DOMContentLoaded", () => {
  init();
});

document.addEventListener("tabby:init", (e) => {
  init(e?.detail?.selector, e?.detail?.options);
});

document.addEventListener(
  "tabby",
  (event) => {
    const tab = event.target;
    const tabContainer = tab.closest("[data-tabs]");
    const hash = tab.href.substring(tab.href.indexOf("#") + 1);
    if (tab.dataset.activeClasses) {
      tab.classList.add(...tab.dataset.activeClasses.split(" "));
      tab.classList.remove(...tab.dataset.inactiveClasses.split(" "));
      event?.detail?.previousTab?.classList?.remove(
        ...tab.dataset.activeClasses.split(" "),
      );
    } else {
      tab.classList.add("active");
      event?.detail?.previousTab?.classList?.remove("active");
    }

    if (tabContainer?.hasAttribute("data-tabs-persistance-selector")) {
      const tabHiddenField = document.querySelector(
        tabContainer.dataset.tabsPersistanceSelector,
      );
      if (tabHiddenField) {
        tabHiddenField.value = hash;
      }
    }

    if (!tab.hasAttribute("data-tabs-skiphistory")) {
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(undefined, undefined, `#${hash}`);
    }
  },
  false,
);
