const renderDiscount = (cart, discount, currency, locale) => {
  const cartDiscount = cart.querySelector('.cart-discount');
  if (cartDiscount && discount && currency) {
    if (discount > 0) {
      const formattedDiscount = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(discount);
      cartDiscount.textContent = `- ${formattedDiscount}`;
      cartDiscount.parentNode.parentNode.classList.remove('tw-hidden');
    } else {
      cartDiscount.parentNode.parentNode.classList.add('tw-hidden');
    }
  }
};

const renderTotal = (cart, total, currency, locale) => {
  const cartTotal = cart.querySelector('.cart-total');
  if (cartTotal) {
    cartTotal.textContent = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(total);
  }
};

const hideShowNext = (cart, quantity) => {
  const actions = cart.querySelector('.actions');
  if (actions === null) {
    return;
  }

  if (actions.dataset.mandatory !== 'true' || quantity > 0) {
    actions.classList.remove('tw-hidden');
  } else {
    actions.classList.add('tw-hidden');
  }
};

const renderCart = (orderForm) => {
  const selector = orderForm.dataset.cartSelector;
  const cart = document.querySelector(selector);

  const locale = orderForm.dataset.cartLocale;

  const template = cart.querySelector('.cart-item-template');
  const itemsContainer = cart.querySelector('.items');
  itemsContainer.textContent = '';

  let total = 0.0;
  let currency = 'USD';

  let totalQuantity = 0;

  orderForm.querySelectorAll('[data-cart-item-id]').forEach((item) => {
    const clone = template.content.cloneNode(true);

    const itemName = clone.querySelector('.item-name');
    itemName.textContent = item.dataset.cartItemName;

    let quantity = 0;
    if (item.dataset.cartItemQuantity) {
      quantity = parseInt(item.dataset.cartItemQuantity, 10);
      if (Number.isNaN(quantity)) {
        quantity = 0;
      } else {
        const itemQuantity = clone.querySelector('.item-quantity');
        itemQuantity.textContent = quantity;
      }
    }

    if (item.dataset.cartItemPrice && item.dataset.cartItemCurrency) {
      let price = parseFloat(item.dataset.cartItemPrice);
      currency = item.dataset.cartItemCurrency;

      if (Number.isNaN(price)) {
        price = 0.0;
      }
      const subtotal = quantity * price;
      total += subtotal;

      const itemPrice = clone.querySelector('.item-price');
      itemPrice.textContent = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(subtotal);
    }

    if (quantity > 0) {
      itemsContainer.appendChild(clone);
      totalQuantity += quantity;
    }
  });

  const discount = orderForm.dataset.cartDiscount;
  const discountCurrency = orderForm.dataset.cartDiscountCurrency;

  if (discount) {
    total -= discount;
    renderDiscount(cart, discount, discountCurrency, locale);
  }

  renderTotal(cart, total, currency, locale);
  hideShowNext(cart, totalQuantity);
};

const init = () => {
  document.querySelectorAll('[data-cart-selector]').forEach((el) => {
    renderCart(el);

    el.querySelectorAll('.cart-quantity').forEach((quantityEl) => {
      quantityEl.addEventListener('change', (e) => {
        const parent = e.target.parentNode;
        parent.dataset.cartItemQuantity = e.target.value;
        renderCart(el);
      });
    });
  });
};

document.addEventListener('DOMContentLoaded', init);
document.addEventListener('shopping_cart:rebind', init);
