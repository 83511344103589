import Rails from "@rails/ujs";
import { i18n } from "../Internationalization";

const hideRequiredNotices = () => {
  const notice = document.querySelector("#medpro_required_info");
  notice?.classList?.add("tw-hidden");

  const requiredError = document.querySelector("#medpro_required_error");
  requiredError?.classList?.add("tw-hidden");
};

const disableRequiredValidation = () => {
  const medproIdField = document.querySelector(
    "#registration_medpro_search_medpro_id",
  );
  delete medproIdField.dataset.formValidateRequired;
  document.dispatchEvent(new Event("rebind-validation"));
};

const selectCard = (el) => {
  const medproId = el.dataset.medproSelect;
  const medproIdField = document.querySelector(
    "#registration_medpro_search_medpro_id",
  );
  medproIdField.value = medproId;

  const regLastName = document.querySelector("#registration_last_name");
  if (
    regLastName !== null &&
    regLastName.value === "" &&
    el.dataset.medproLastName !== ""
  ) {
    regLastName.value = el.dataset.medproLastName;
  }

  const regFirstName = document.querySelector("#registration_first_name");
  if (
    regFirstName != null &&
    regFirstName.value === "" &&
    el.dataset.medproFirstName !== ""
  ) {
    regFirstName.value = el.dataset.medproFirstName;
  }

  hideRequiredNotices();

  document.querySelectorAll("[data-medpro-select]").forEach((button) => {
    const span = button.querySelector("span");
    const img = button.querySelector("img");
    if (button.dataset.medproSelect === medproId) {
      span.textContent = i18n.t("actions.selected");
      img.classList.remove("tw-hidden");
    } else {
      span.textContent = i18n.t("actions.select");
      img.classList.add("tw-hidden");
    }
  });
};

const bindControls = () => {
  document.querySelectorAll("[data-medpro-unverify]").forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault();
      const medproIdField = document.querySelector(
        "#registration_medpro_search_medpro_id",
      );
      medproIdField.value = "";
      document
        .querySelector("#medpro_verify_later")
        .classList.remove("tw-hidden");
      document.querySelectorAll("[data-medpro-id]").forEach((card) => {
        card.classList.add("tw-hidden");
      });
      document.querySelector("#medpro_header")?.classList?.add("tw-hidden");
      disableRequiredValidation();
      hideRequiredNotices();
    });
  });
  document.querySelectorAll("[data-medpro-select]").forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault();
      selectCard(el);
    });
  });
};

const setResult = (e) => {
  const medproIdField = document.querySelector(
    "#registration_medpro_search_medpro_id",
  );
  medproIdField.value = e.detail.medproId;

  if (e.detail.assumedId !== "") {
    const recordSelectButton = document.querySelector(
      `[data-medpro-select="${e.detail.assumedId}"]`,
    );
    if (recordSelectButton) {
      selectCard(recordSelectButton);
    }
  }

  const requiredNotice = document.querySelector("#medpro_required_info");
  requiredNotice?.classList?.add("tw-hidden");

  const container = document.querySelector("[data-medpro-verify-info]");
  container?.classList?.remove("tw-hidden");
  if (
    e.detail.resultState === "not_found" ||
    e.detail.resultState === "error"
  ) {
    hideRequiredNotices();
    disableRequiredValidation();
  }
  bindControls();
};

const init = () => {
  const medproVerificationComponent = document.querySelector(
    "[data-medpro-registration]",
  );
  if (medproVerificationComponent) {
    const verifyUrl = medproVerificationComponent.dataset.medproRegistration;
    const verifyButton =
      medproVerificationComponent.querySelector(".verify_medpro");
    verifyButton.addEventListener("click", (e) => {
      e.preventDefault();
      const medproIdField = document.querySelector(
        "#registration_medpro_search_medpro_id",
      );
      medproIdField.value = "";

      const data = {
        "medpro_search[first_name]": document.querySelector(
          "#registration_first_name",
        )?.value,
        "medpro_search[last_name]": document.querySelector(
          "#registration_last_name",
        )?.value,
        "medpro_search[designation]": document.querySelector(
          "#registration_medpro_search_designation",
        ).value,
        "medpro_search[state_license]": document.querySelector(
          "#registration_medpro_search_state_license",
        ).value,
        "medpro_search[identifier_type]": document.querySelector(
          "#registration_medpro_search_identifier_type",
        ).value,
        "medpro_search[identifier_number]": document.querySelector(
          "#registration_medpro_search_identifier_number",
        ).value,
      };

      Rails.ajax({
        type: "GET",
        url: verifyUrl,
        data: new URLSearchParams(data).toString(),
      });
      return false;
    });
    bindControls();
  }
};

document.addEventListener("DOMContentLoaded", init);
document.addEventListener("medpro:result", setResult);
document.addEventListener("form-validate:fail", (e) => {
  if (e.detail["#registration_medpro_search_medpro_id"]?.isValid === false) {
    hideRequiredNotices();

    const requiredError = document.querySelector("#medpro_required_error");
    requiredError?.classList?.remove("tw-hidden");
  }
});
