import $ from 'jquery';

export const initHideShowLinks = () => {
  $('body').on('click', 'a[data-show]', (event) => {
    event.preventDefault();
    $(`#${$(this).data('show')}`).removeClass('hidden');
  });

  $('body').on('click', 'a[data-hide]', (event) => {
    event.preventDefault();
    $(`#${$(this).data('hide')}`).addClass('hidden');
  });
};

export const setUniqIds = (editorId) => {
  const uniqueId = (id) => `${id}_${Math.random().toString(36).substr(2, 16)}`;
  const newId = uniqueId(editorId);
  $(`#${editorId}`).attr('id', newId);
  $(`#toolbar-${editorId}`).attr('id', `toolbar-${newId}`);
};
window.setUniqIds = setUniqIds;

export const initializeRemoteSearch = (url) => {
  $(".remote-search").dropdown({
    fullTextSearch: true,
    apiSettings: {
      url: url,
    },
  });
};
window.initializeRemoteSearch = initializeRemoteSearch;
