import $ from 'jquery';
import { initAllFlatpickrMulti } from '../DatetimeInit';
import { getCSRFTokenValue } from '../CsrfToken';

const checkNextButton = () => {
  const result = $('button.room-type-selected:visible').length > 0 || $('#assigned-rooms');
  if (result) {
    $('#next-button').removeClass('disabled').addClass('green');
  } else {
    $('#next-button').removeClass('green').addClass('disabled');
  }
};

const removeRoomRequest = (e) => {
  const btnEl = e.target;
  const organizationId = btnEl.getAttribute('data-organization-id');
  const eventId = btnEl.getAttribute('data-event-id');
  const roomRequestIds = btnEl.attributes.room_request_ids.value;

  $.ajax({
    url: `/organizations/${organizationId}/events/${eventId}/room_requests/${roomRequestIds}`,
    type: 'DELETE',
    headers: {
      'X-CSRF-Token': getCSRFTokenValue(),
    },
    success(data) {
      const suffixId = data.room_type_id;

      $(`#room-type-selected-${suffixId}`)
        .attr('room_request_id', '')
        .hide();

      $(`#room-type-select-${suffixId}`).show();
      $('#room-request-cart').html(data.room_type_cart_partial);

      checkNextButton();
    },
  });
};

const addRoomRequest = (e) => {
  const btnEl = e.currentTarget;
  const onBehalfOf = btnEl.getAttribute('data-on-behalf-of') === 'true';
  const organizationId = btnEl.getAttribute('data-organization-id');
  const eventId = btnEl.getAttribute('data-event-id');
  const roomTypeId = btnEl.getAttribute('data-roomtype-id');
  const checkin = $('#checkin')[0].value;
  const checkout = $('#checkout')[0].value;
  const roomCount = $(`#room-count-${roomTypeId}`)[0].value;
  const guestCount = $(`#guest-count-${roomTypeId}`)[0].value;

  $.ajax({
    url: `/organizations/${organizationId}/events/${eventId}/room_requests`,
    type: 'POST',
    headers: {
      'X-CSRF-Token': getCSRFTokenValue(),
    },
    data: {
      room_type_id: roomTypeId,
      checkin,
      checkout,
      room_count: roomCount,
      guest_count: guestCount,
      on_behalf_of: onBehalfOf,
    },
    success(data) {
      const suffixId = data.room_type_id;
      $('#flash-notice').remove();
      $(`#room-type-select-${suffixId}`).hide();
      $(`#error_message_${suffixId}`).toggleClass('hidden', true);
      $(`#room-type-selected-${suffixId}`)
        .attr('room_request_ids', data.room_request_ids)
        .show();
      $('#room-request-cart').html(data.room_type_cart_partial);
      checkNextButton();
    },
    error(jqXHR) {
      const suffixId = jqXHR.responseJSON.room_type_id;
      $(`#error_message_${suffixId} .content`).text(jqXHR.responseJSON.error);
      $(`#error_message_${suffixId}`).toggleClass('hidden', false);
    },
  });
};

const updateAvailability = (e) => {
  e.preventDefault();

  let path = $(e.target).attr('href').split('?')[0];
  const date1 = $('#checkin').val();
  const date2 = $('#checkout').val();
  const checkin = new Date(date1);
  const checkout = new Date(date2);

  if (checkin <= checkout) {
    path = `${path}?checkin=${date1}&checkout=${date2}`;
  } else {
    path = `${path}?checkin=${date2}&checkout=${date1}`;
  }

  $(e.target).attr('href', path);
  if ($('button.room-type-selected:visible').length === 0) {
    window.location.href = path;
  } else {
    $('button.room-type-selected:visible').each((ind, el) => {
      const onBehalfOf = el.getAttribute('data-on-behalf-of') === 'true';
      const roomRequestIds = el.attributes.room_request_ids.value;
      const organizationId = el.getAttribute('data-organization-id');
      const eventId = el.getAttribute('data-event-id');
      const roomTypeId = el.getAttribute('data-roomtype-id');
      const roomCheckin = $('#checkin')[0].value;
      const roomCheckout = $('#checkout')[0].value;
      const roomCount = $(`#room-count-${roomTypeId}`)[0].value;
      const guestCount = $(`#guest-count-${roomTypeId}`)[0].value;

      $.ajax({
        url: `/organizations/${organizationId}/events/${eventId}/room_requests/${roomRequestIds}`,
        type: 'DELETE',
        headers: {
          'X-CSRF-Token': getCSRFTokenValue(),
        },
        success(data) {
          const suffixId = data.room_type_id;

          $(`#room-type-selected-${suffixId}`)
            .attr('room_request_id', '');

          $.ajax({
            url: `/organizations/${organizationId}/events/${eventId}/room_requests`,
            type: 'POST',
            headers: {
              'X-CSRF-Token': getCSRFTokenValue(),
            },
            data: {
              room_type_id: roomTypeId,
              checkin: roomCheckin,
              checkout: roomCheckout,
              room_count: roomCount,
              guest_count: guestCount,
              on_behalf_of: onBehalfOf,
            },
            success() {
              const newPath = document.querySelector('#room-block-availability-update-button').href;
              window.location.href = newPath;
            },
          });
        },
      });
    });
  }
};

const initAdditionalDetails = (url) => {
  $('body').on('click', '#next-button.additional-details', (e) => {
    e.preventDefault();

    let roomRequestId = localStorage.getItem('roomRequestId');
    let additionalRequest = localStorage.getItem('additionalRequirements');
    let loyaltyName = localStorage.getItem('loyaltyName');
    let loyaltyNumber = localStorage.getItem('loyaltyNumber');

    if (roomRequestId) {
      roomRequestId = roomRequestId.replace(/"/g, '');
    }

    if (additionalRequest) {
      additionalRequest = additionalRequest.replace(/"/g, '');
    }

    if (loyaltyName) {
      loyaltyName = loyaltyName.replace(/"/g, '');
    }

    if (loyaltyNumber) {
      loyaltyNumber = loyaltyNumber.replace(/"/g, '');
    }

    $.ajax({
      url,
      type: 'PUT',
      dataType: 'script',
      headers: {
        'X-CSRF-Token': getCSRFTokenValue(),
      },
      data: {
        room_request_cart: {
          room_requests_attributes: {
            id: roomRequestId,
            additional_request: additionalRequest,
            loyalty_name: loyaltyName,
            loyalty_number: loyaltyNumber,
          },
        },
      },
      success() {
        localStorage.clear();
      },
    });
  });
};

const enableSearch = () => {
  const checkin = document.querySelector('#checkin');
  const checkout = document.querySelector('#checkout');
  const submitBtn = document.querySelector('#room-block-availability-update-button');

  const listener = () => {
    if (checkin.value && checkout.value) {
      submitBtn.classList.remove('disabled');
    } else if (!submitBtn.classList.contains('disabled')) {
      submitBtn.classList.add('disabled');
    }
  };
  checkin.addEventListener('change', listener);
  checkout.addEventListener('change', listener);
};

export const initRoomTypeSelection = (occupantsPath) => {
  checkNextButton();
  document.querySelector('#room-block-availability-update-button').addEventListener('click', updateAvailability);
  document.querySelectorAll('.room-type-selected').forEach((el) => {
    el.addEventListener('click', removeRoomRequest);
  });
  document.querySelectorAll('.room-type-select').forEach((el) => {
    el.addEventListener('click', addRoomRequest);
  });

  initAllFlatpickrMulti({ allowInput: false });
  initAdditionalDetails(occupantsPath);
  enableSearch();
};
window.initRoomTypeSelection = initRoomTypeSelection;
