import $ from "jquery";
import { getCSRFTokenValue } from "../CsrfToken";

const setTermsState = () => {
  const terms = document.querySelector(".terms_of_service");
  document.querySelectorAll(".terms_required").forEach((el) => {
    el.classList.toggle("disabled", !terms.checked);
  });
};

export const initGroupizeTerms = () => {
  const tos = document.querySelector(".terms_of_service");
  if (tos) {
    tos.addEventListener("change", () => {
      setTermsState();
    });
  }
  setTermsState();
};

export const handleAttendeeTypeChange = () => {
  const attendeeTypeSelector = document.querySelector(
    "#registration_attendee_type_id",
  );
  if (attendeeTypeSelector) {
    attendeeTypeSelector.addEventListener("change", () => {
      $.ajax({
        url: window.location.href,
        type: "GET",
        dataType: "script",
        data: {
          attendee_type_id: attendeeTypeSelector.value,
        },
        headers: {
          "X-CSRF-Token": getCSRFTokenValue(),
        },
      });
    });
  }
};
