import $ from "jquery";
import { initDatePickerInBlock, initTimePickerInBlock } from "../DatetimeInit";
import { initializeSemanticUI } from "../../plugins/SemanticUI";

const newAirCompanionForm = (item) => {
  const elem = item[0];
  initDatePickerInBlock(elem);
  initTimePickerInBlock(elem);
  initializeSemanticUI();
  elem.dispatchEvent(new Event("airportselector:rebind", { bubbles: true }));
};

const bindAirRequestForm = () => {
  initDatePickerInBlock(".air_request_form", null, { dateFormat: "m/d/Y" });
  // Not sure why both of these are needed but one fixes the validation and the other
  // ensures that the date picker, time picker, airport are all bound
  $("#companion-form-container").on("cocoon:after-insert", (e, item) => {
    // Cocoon doesn't know about the form-validate plugin, so we need to update them to the right container
    item[0].querySelectorAll("[data-form-validate]").forEach((field) => {
      if (field.dataset.formValidateErrorsContainer) {
        const newErrorContainer = `${field.id}_errors`;
        const originalErrorContainer = item[0].querySelector(
          field.dataset.formValidateErrorsContainer,
        );
        originalErrorContainer.id = newErrorContainer;
        field.dataset.formValidateErrorsContainer = `#${newErrorContainer}`;
      }
    });
    document.dispatchEvent(new Event("rebind-validation"));
  });

  $("#companion-form-container").on("cocoon:after-remove", () => {
    document.dispatchEvent(new Event("rebind-validation"));
  });

  $("#companion")
    .on("cocoon:after-insert", (e, item) => {
      newAirCompanionForm(item);
    })
    .on("cocoon:before-insert", (e, item) => {
      item.fadeIn("slow");
    })
    .on("cocoon:before-remove", (e, item) => {
      $(this).data("remove-timeout", 1000);
      item.fadeOut("slow");
    });
};

const init = () => {
  if (document.querySelector("[data-air-request-form]")) {
    bindAirRequestForm();
  }
};

document.addEventListener("DOMContentLoaded", init);
