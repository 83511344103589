import $ from "jquery";

export const bindAirportSelector = (elem) => {
  const url = elem.dataset.url || elem.parentElement.dataset.url;

  $(elem).dropdown({
    clearable: true,
    apiSettings: {
      url,
    },
    filterRemoteData: false,
  });
  elem.classList.add("remote-bound");
};

const init = () => {
  document.querySelectorAll(".airport-selector").forEach((elem) => {
    if (elem.tagName === "SELECT") {
      bindAirportSelector(elem);
    }
  });
};

document.addEventListener("airportselector:rebind", init);
