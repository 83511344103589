/* eslint-disable no-underscore-dangle */
import SignaturePad from "signature_pad";

// eslint-disable-next-line func-names

/**
 * Crop signature canvas to only contain the signature and no whitespace.
 *
 * @since 1.0.0
 * CREDIT: https://github.com/szimek/signature_pad/issues/49#issuecomment-260976909
 */
const cropSignatureCanvas = (canvas) => {
  // First duplicate the canvas to not alter the original
  const croppedCanvas = document.createElement("canvas");
  const croppedCtx = croppedCanvas.getContext("2d");

  croppedCanvas.width = canvas.width;
  croppedCanvas.height = canvas.height;
  croppedCtx.drawImage(canvas, 0, 0);

  // Next do the actual cropping
  let w = croppedCanvas.width;
  let h = croppedCanvas.height;
  const pix = { x: [], y: [] };
  const imageData = croppedCtx.getImageData(
    0,
    0,
    croppedCanvas.width,
    croppedCanvas.height,
  );
  let x;
  let y;
  let index;

  // eslint-disable-next-line no-plusplus
  for (y = 0; y < h; y++) {
    // eslint-disable-next-line no-plusplus
    for (x = 0; x < w; x++) {
      index = (y * w + x) * 4;
      if (imageData.data[index + 3] > 0) {
        pix.x.push(x);
        pix.y.push(y);
      }
    }
  }
  pix.x.sort((a, b) => a - b);
  pix.y.sort((a, b) => a - b);
  const n = pix.x.length - 1;

  w = pix.x[n] - pix.x[0];
  h = pix.y[n] - pix.y[0];
  const cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

  croppedCanvas.width = w;
  croppedCanvas.height = h;
  croppedCtx.putImageData(cut, 0, 0);

  return croppedCanvas.toDataURL();
};

const resizeCanvas = (canvas, signaturePad) => {
  const ratio = Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext("2d").scale(ratio, ratio);
  signaturePad.clear(); // otherwise isEmpty() might return incorrect value
};

export const initSignaturePad = (elem) => {
  const container = elem;
  const selector = container.dataset.signatureField;

  const dataField = document.querySelector(`${selector}_field`);
  const canvas = document.querySelector(`${selector}_signature_pad`);
  const signaturePad = new SignaturePad(canvas);
  const clearButton = document.querySelector(`${selector}_signature_pad_clear`);

  resizeCanvas(canvas, signaturePad);

  try {
    if (dataField.value && dataField.value !== "") {
      signaturePad.fromDataURL(dataField.value);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Could not draw saved signature", e);
  }

  signaturePad.addEventListener("endStroke", () => {
    dataField.value = cropSignatureCanvas(canvas);
  });

  clearButton.addEventListener("click", (e) => {
    e.preventDefault();
    signaturePad.clear();
  });
};

export const initSignaturePadReadOnly = (elem) => {
  const container = elem;
  const selector = container.dataset.signatureField;

  const dataField = document.querySelector(`${selector}_field`);
  const label = document.querySelector(`${selector}_label`);
  const canvas = document.querySelector(`${selector}_signature_pad`);
  const signaturePad = new SignaturePad(canvas);
  const clearButton = document.querySelector(`${selector}_signature_pad_clear`);
  clearButton.classList.add("hide");

  resizeCanvas(canvas, signaturePad);

  try {
    if (dataField.value && dataField.value !== "") {
      signaturePad.fromDataURL(dataField.value);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Could not draw saved signature", e);
  }

  delete dataField.dataset.formValidate; // Cannot be required if it's readonly
  label.classList.remove("required");

  signaturePad.off();
  clearButton.addEventListener("click", (e) => {
    e.preventDefault();
  });
};

const init = () => {
  document.querySelectorAll("[data-signature-field]").forEach((elem) => {
    initSignaturePad(elem);
  });
};

export const initReadOnly = () => {
  document.querySelectorAll("[data-signature-field]").forEach((elem) => {
    initSignaturePadReadOnly(elem);
  });
};

document.addEventListener("DOMContentLoaded", init);
document.addEventListener("signature_pad:init_read_only", initReadOnly);
